.file-uploader {
  width: 100%;
  height: 200px;
  padding: 24px;
  background-color: #fafafc;
  margin-bottom: 24px;
  border: 2px dashed var(--border-color); }

.file_upload--limits li {
  list-style: disc;
  margin: 0  0  0 32px;
  font-size: 0.95rem; }

.file-uploader--dragging {
  background-color: #999999; }

.file-uploader__contents {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center; }

.file-uploader__file-name {
  font-weight: 700; }
