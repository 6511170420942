.timesheets .timesheet-header {
  display: grid;
  grid-template-columns: 2fr 1fr auto; }
  .timesheets .timesheet-header .status {
    text-align: right; }
  @media (max-width: 576px) {
    .timesheets .timesheet-header {
      grid-template-columns: 1fr; } }

.timesheets .timesheet-editor {
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 10px; }
  .timesheets .timesheet-editor .timesheet-row {
    display: grid;
    grid-template-columns: 3fr 0.25fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    align-items: center;
    padding: 10px 0;
    grid-template-areas: "detail breif day0 day1 day2 day3 day4 day5 day6 total"; }
    .timesheets .timesheet-editor .timesheet-row .detail {
      grid-area: detail; }
    .timesheets .timesheet-editor .timesheet-row .day {
      text-align: center;
      box-sizing: border-box;
      border-bottom: 4px solid transparent;
      position: relative; }
      .timesheets .timesheet-editor .timesheet-row .day .day-of-week {
        font-weight: bold; }
      .timesheets .timesheet-editor .timesheet-row .day .date {
        font-weight: 300;
        font-size: 0.8rem; }
      .timesheets .timesheet-editor .timesheet-row .day input {
        width: 100%;
        text-align: center;
        font-size: 1rem;
        border: none; }
      .timesheets .timesheet-editor .timesheet-row .day .units {
        font-weight: 300;
        font-size: 0.8rem;
        color: var(--font-minor-color); }
      .timesheets .timesheet-editor .timesheet-row .day.range-warning {
        border-bottom: 4px solid var(--warning-color); }
      .timesheets .timesheet-editor .timesheet-row .day.range-error {
        border-bottom: 4px solid var(--error-color); }
      .timesheets .timesheet-editor .timesheet-row .day .notes {
        display: none;
        bottom: 4px;
        right: 8px;
        position: absolute; }
        .timesheets .timesheet-editor .timesheet-row .day .notes.filled {
          display: block; }
    .timesheets .timesheet-editor .timesheet-row .day0 {
      grid-area: day0; }
    .timesheets .timesheet-editor .timesheet-row .day1 {
      grid-area: day1; }
    .timesheets .timesheet-editor .timesheet-row .day2 {
      grid-area: day2; }
    .timesheets .timesheet-editor .timesheet-row .day3 {
      grid-area: day3; }
    .timesheets .timesheet-editor .timesheet-row .day4 {
      grid-area: day4; }
    .timesheets .timesheet-editor .timesheet-row .day5 {
      grid-area: day5; }
    .timesheets .timesheet-editor .timesheet-row .day6 {
      grid-area: day6; }
    .timesheets .timesheet-editor .timesheet-row .time {
      padding: 5px;
      border: 2px solid transparent; }
      .timesheets .timesheet-editor .timesheet-row .time input {
        outline: none; }
      .timesheets .timesheet-editor .timesheet-row .time:focus-within {
        border: 2px solid var(--feature-colour);
        border-radius: 2px; }
    .timesheets .timesheet-editor .timesheet-row .total {
      grid-area: total;
      text-align: center;
      font-weight: bold; }
      .timesheets .timesheet-editor .timesheet-row .total .units {
        font-weight: 300;
        font-size: 0.8rem;
        color: var(--font-minor-color); }
      .timesheets .timesheet-editor .timesheet-row .total .time {
        padding: 0; }
    .timesheets .timesheet-editor .timesheet-row.assignment {
      border-bottom: 1px dashed var(--border-color); }
      .timesheets .timesheet-editor .timesheet-row.assignment .day:hover {
        background-color: var(--feature-colour-light-3);
        cursor: pointer; }
        .timesheets .timesheet-editor .timesheet-row.assignment .day:hover input {
          background-color: var(--feature-colour-light-3); }
        .timesheets .timesheet-editor .timesheet-row.assignment .day:hover .notes {
          display: block; }
    .timesheets .timesheet-editor .timesheet-row .client {
      font-weight: bold; }
    .timesheets .timesheet-editor .timesheet-row.header {
      border: none; }
    .timesheets .timesheet-editor .timesheet-row.divider {
      background-color: var(--grey-color);
      border: none;
      padding: 10px; }
    .timesheets .timesheet-editor .timesheet-row.totals {
      grid-template-columns: 10fr 1fr;
      background-color: var(--grey-color);
      padding: 10px; }
    @media (max-width: 576px) {
      .timesheets .timesheet-editor .timesheet-row {
        grid-template-columns: auto;
        grid-template-areas: "detail detail detail detail detail detail total" "day0 day1 day2 day3 day4 day5 day6";
        align-items: stretch; }
        .timesheets .timesheet-editor .timesheet-row.assignment .detail {
          padding-bottom: 5px; }
        .timesheets .timesheet-editor .timesheet-row .day {
          border-right: 1px solid var(--border-color); }
          .timesheets .timesheet-editor .timesheet-row .day input {
            max-width: 40px; }
        .timesheets .timesheet-editor .timesheet-row .day6 {
          border-right: none; }
        .timesheets .timesheet-editor .timesheet-row .total {
          background-color: var(--grey-color);
          border: 1px solid var(--grey-color); } }
  .timesheets .timesheet-editor .period-selector {
    display: flex;
    align-items: stretch;
    background-color: var(--grey-color);
    width: auto;
    padding: 5px;
    text-align: center; }
    .timesheets .timesheet-editor .period-selector label {
      font-weight: 300;
      font-size: 0.8rem;
      display: block;
      text-align: center;
      margin: 0; }
    .timesheets .timesheet-editor .period-selector .skip {
      flex-grow: 1;
      text-align: center; }
    .timesheets .timesheet-editor .period-selector .calendar {
      flex-grow: 3;
      text-align: center; }
  .timesheets .timesheet-editor .add-row-title {
    font-size: 0.8rem; }

.timesheets .badge.status-NONE {
  background-color: var(--grey-color);
  padding: 5px 10px; }

.timesheets .badge.status-DRAFT {
  background-color: var(--warning-color);
  padding: 5px 10px; }

.timesheets .badge.status-SUBMITTED, .timesheets .badge.status-SENT {
  background-color: var(--feature-colour);
  color: white;
  padding: 5px 10px; }

.timesheets .badge.status-APPROVED, .timesheets .badge.status-ACCEPTED {
  background-color: var(--success-color);
  color: white;
  padding: 5px 10px; }

.timesheets .badge.status-REJECTED {
  background-color: var(--error-color);
  color: white;
  padding: 5px 10px; }

.timesheets .quantity .amount {
  font-size: 2rem;
  font-weight: bold;
  padding-right: 4px; }

.timesheets .quantity .units {
  font-size: 0.8rem;
  color: var(--font-minor-color);
  font-weight: 300;
  font-size: 0.8rem; }

.timesheets .quantity.nonbillable {
  color: var(--error-color); }

.timesheets.data-items .timesheet {
  min-height: auto;
  height: auto;
  overflow: visible;
  grid-template-columns: 100px auto;
  grid-template-rows: auto auto;
  grid-template-areas: "thumbnail details"  "entries entries"; }
  .timesheets.data-items .timesheet .thumbnail .profile-thumbnail {
    grid-area: thumbnail; }
  .timesheets.data-items .timesheet .details {
    grid-area: details;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-areas: "name totals" "statusreport statusreport"; }
  .timesheets.data-items .timesheet .name {
    grid-area: name;
    font-weight: bold; }
  .timesheets.data-items .timesheet .department {
    font-weight: normal;
    text-transform: none;
    font-size: 0.8rem; }
  .timesheets.data-items .timesheet .totals {
    grid-area: totals; }
  .timesheets.data-items .timesheet .entries {
    grid-area: entries;
    font-size: 0.8rem;
    border-top: 2px solid var(--border-color);
    margin: 12px 0;
    padding: 4px;
    background-color: var(--background-colour-light1); }
    .timesheets.data-items .timesheet .entries .entry {
      display: grid;
      padding: 4px;
      grid-template-columns: 20px auto 40px; }
      .timesheets.data-items .timesheet .entries .entry + .entry {
        border-top: 1px dashed var(--border-color); }
      .timesheets.data-items .timesheet .entries .entry .amount {
        text-align: right; }
      .timesheets.data-items .timesheet .entries .entry .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
  .timesheets.data-items .timesheet.status-DRAFT .thumbnail .profile-thumbnail {
    border: 3px solid var(--warning-color); }
  .timesheets.data-items .timesheet.status-SUBMITTED .thumbnail .profile-thumbnail {
    border: 3px solid var(--feature-colour); }
  .timesheets.data-items .timesheet.status-APPROVED .thumbnail .profile-thumbnail {
    border: 3px solid var(--success-color); }
  .timesheets.data-items .timesheet.status-REJECTED .thumbnail .profile-thumbnail {
    border: 3px solid var(--error-color); }
  .timesheets.data-items .timesheet .smiley {
    font-size: 2rem; }
  .timesheets.data-items .timesheet .totals {
    text-align: right;
    display: flex;
    justify-content: flex-end; }
    .timesheets.data-items .timesheet .totals .hours {
      padding-left: 8px;
      margin: 0 8px; }
    .timesheets.data-items .timesheet .totals .amount {
      padding: 0; }
    .timesheets.data-items .timesheet .totals .units {
      display: block; }
    .timesheets.data-items .timesheet .totals .hours + .hours {
      border-left: 1px solid var(--border-color); }
  .timesheets.data-items .timesheet .tools {
    z-index: 1000;
    display: block;
    overflow: hidden;
    transition: height 0.25s ease-in-out, opacity 0.25s ease-in-out;
    display: flex;
    align-items: center;
    height: 0;
    opacity: 0; }
  .timesheets.data-items .timesheet:hover .tools {
    height: 60px;
    min-height: 60px;
    opacity: 1; }
  .timesheets.data-items .timesheet .status-report {
    display: flex;
    padding: 4px;
    border-top: 1px solid var(--border-color);
    grid-area: statusreport;
    background-color: var(--background-colour-light1); }
    .timesheets.data-items .timesheet .status-report .status-report-body {
      font-size: 0.8rem;
      padding: 0 8px;
      color: var(--font-minor-color); }

.timesheets.data-items .data-divider {
  margin-top: 40px;
  grid-column-start: span 2;
  display: flex; }
  .timesheets.data-items .data-divider .date-label {
    font-weight: 300;
    color: var(--font-minor-color);
    font-size: 0.8rem; }
  .timesheets.data-items .data-divider .title {
    flex: 1; }
  @media (max-width: 991px) {
    .timesheets.data-items .data-divider {
      grid-column-start: unset; } }
  @media (min-width: 1400px) {
    .timesheets.data-items .data-divider {
      grid-column-start: span 3; } }

.timesheets.data-items .data-grid.grid {
  grid-template-columns: 1fr 1fr; }
  @media (max-width: 991px) {
    .timesheets.data-items .data-grid.grid {
      grid-template-columns: 1fr; } }
  @media (min-width: 1400px) {
    .timesheets.data-items .data-grid.grid {
      grid-template-columns: 1fr 1fr 1fr; } }

.timesheets .timesheet-controls {
  display: flex; }
  .timesheets .timesheet-controls .timesheet-buttons {
    flex: 1 1 400px; }

.timesheets .timesheet-status-report-body {
  background-color: var(--grey-background-color);
  padding: 8px;
  min-height: 100px; }

.timesheets .timesheet-footer {
  display: flex;
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid var(--border-color); }
  .timesheets .timesheet-footer .timesheet-logs, .timesheets .timesheet-footer .timesheet-status-report {
    flex: 1 1 400px; }
  .timesheets .timesheet-footer .timesheet-status-report-header {
    display: flex;
    align-items: center; }
    .timesheets .timesheet-footer .timesheet-status-report-header label {
      flex: 1 1 auto;
      font-weight: bold; }
  .timesheets .timesheet-footer .smiley {
    font-size: 2rem; }

.timesheet .timesheet-header h1 {
  text-transform: uppercase;
  overflow-wrap: break-word; }

.timesheet .timesheet-header .status {
  display: flex;
  justify-content: flex-end; }
  .timesheet .timesheet-header .status .profile-photo {
    height: 64px;
    width: 64px;
    margin-left: 20px; }

.timesheet-logs, .timesheet-subscribers, .timesheet-notes {
  font-size: 0.8rem;
  color: var(--font-minor-color); }
  .timesheet-logs .timesheet-log td, .timesheet-subscribers .timesheet-log td, .timesheet-notes .timesheet-log td {
    padding-left: 8px; }

.timesheet-subscribers {
  display: flex;
  align-items: center; }
  .timesheet-subscribers .timesheet-subscribers-icon {
    padding-right: 12px; }
