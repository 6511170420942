.form-group label {
  font-weight: bold; }

.form-group.inline input + label {
  padding-left: 8px; }

.form-group.attachments .attachments-list {
  display: grid; }

.form-month-year .date-selectors {
  display: flex;
  max-width: 200px; }
  .form-month-year .date-selectors select {
    display: inline-block; }
  .form-month-year .date-selectors.year .year {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none; }
  .form-month-year .date-selectors.year .month {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none; }

.five-stars {
  unicode-bidi: bidi-override;
  direction: rtl;
  font-size: 2em; }
  .five-stars > .star:hover:before, .five-stars > .star:hover ~ .star:before {
    content: "\2605";
    position: absolute;
    color: inherit; }
  .five-stars > .star {
    display: inline-block;
    position: relative;
    width: 1.1em; }
  .five-stars button, .five-stars a {
    text-decoration: none;
    color: inherit; }

input[type=checkbox] {
  box-shadow: none;
  user-select: none;
  outline: none;
  outline-color: transparent; }
  input[type=checkbox]:focus {
    user-select: none;
    outline: none;
    outline-color: transparent; }
  input[type=checkbox].inline {
    display: inline;
    width: auto;
    height: auto; }

.form-inline.search-form {
  margin-bottom: 4px; }
  .form-inline.search-form input, .form-inline.search-form a {
    font-size: 14px; }

nav.pagination-nav {
  float: left;
  margin-right: 4px; }
  nav.pagination-nav .page-link {
    color: var(--font-color); }

.search-form .help-text {
  padding-left: 4px; }

.model-extra-buttons {
  width: 100%; }

.model-group {
  padding: 10px; }

.modal-dialog {
  margin: 0;
  right: 0;
  position: absolute; }
  .modal-dialog .modal-content {
    border-radius: 0;
    border: none;
    min-height: 100vh;
    min-width: 400px; }

.modal.fade .modal-dialog {
  transform: translate(50px, 0); }

.modal.show .modal-dialog {
  transform: none; }

@media (max-width: 768px) {
  .modal-dialog {
    margin: 5px;
    position: relative; }
    .modal-dialog .modal-content {
      height: unset;
      min-width: unset; } }

.mentions__input {
  padding: 10px;
  overflow: auto;
  height: 150px;
  padding: .375rem .75rem;
  line-height: 1.5;
  color: var(--font-color);
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.mentions__highlighter {
  padding: 10px;
  overflow: auto;
  height: 150px;
  padding: .375rem .75rem;
  line-height: 1.5;
  color: var(--font-color);
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.mentions__suggestions {
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem; }

.mention-item {
  padding: 4px;
  min-width: 200px; }
  .mention-item:hover {
    background-color: #f8f9fa; }

.typeahead-selected {
  border: 1px solid #ced4da;
  border-radius: .25rem; }
  .typeahead-selected.readonly {
    background-color: #e9ecef; }

.wizard-steps {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 40px;
  background-color: var(--background-colour-light1); }
  .wizard-steps .step {
    text-align: center;
    flex: 1;
    position: relative;
    height: 50px;
    border-bottom: solid 4px var(--grey-background-color); }
    .wizard-steps .step.complete {
      border-bottom: solid 4px var(--border-color); }
    .wizard-steps .step.current {
      border-bottom: solid 4px var(--feature-colour);
      background-color: var(--feature-colour-light-2); }
    .wizard-steps .step:hover {
      background-color: var(--feature-colour-light-3); }
    .wizard-steps .step .btn-icon:hover {
      background-color: unset; }

.slider {
  -webkit-appearance: none;
  padding: 25px 10px;
  width: 100%; }
  .slider::-webkit-slider-runnable-track {
    height: 5px;
    background-color: var(--grey-background-color);
    border: none;
    border-radius: 3px; }
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: var(--border-color);
    margin-top: -13px; }
  .slider:focus {
    outline: none; }
  .slider:hover::-webkit-slider-thumb {
    background: var(--feature-colour); }

.drop-zone {
  display: block;
  border: 2px dashed var(--border-color);
  padding: 20px;
  width: 100%;
  min-height: 100px;
  background-color: var(--grey-background-color); }
  .drop-zone.dragging {
    background-color: var(--border-color); }

.radio-group {
  display: flex;
  border: 1px solid var(--border-color);
  padding: 4px;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  border-radius: .25rem; }
  .radio-group .radio-item {
    flex: 1;
    border-radius: .25rem; }
  .radio-group label {
    cursor: pointer;
    margin-bottom: 0;
    padding: 4px;
    color: var(--font-minor-color); }
  .radio-group .selected {
    background-color: var(--feature-colour); }
    .radio-group .selected label {
      color: white; }
  .radio-group input[type=radio] {
    display: none; }

.form-list ul {
  list-style-type: none;
  margin: 5px;
  padding: 5px; }
  .form-list ul li {
    padding: 10px;
    margin-bottom: 4px;
    background-color: var(--grey-background-color);
    display: flex;
    align-items: center; }
    .form-list ul li .label {
      flex-grow: 1; }

.form-list .input-group.add-list-item {
  margin: 5px;
  padding: 5px;
  overflow: hidden;
  width: unset; }
  .form-list .input-group.add-list-item input {
    width: 80%; }

.form-list .delete-button {
  display: none;
  flex-grow: 0; }

.form-list li:hover .delete-button {
  display: block; }

.form-list-item.deleted {
  text-decoration: line-through; }

.react-datepicker .react-datepicker__day {
  color: var(--font-color); }
  .react-datepicker .react-datepicker__day.react-datepicker__day--selected {
    background-color: var(--feature-colour);
    color: white;
    font-weight: bold; }
  .react-datepicker .react-datepicker__day.react-datepicker__day--weekend {
    background-color: var(--grey-background-color); }
    .react-datepicker .react-datepicker__day.react-datepicker__day--weekend.react-datepicker__day--selected {
      color: var(--font-color); }
  .react-datepicker .react-datepicker__day:hover {
    background-color: var(--feature-colour-light-2); }

.react-datepicker .react-datepicker__header {
  color: var(--font-color);
  background-color: var(--grey-background-color); }
  .react-datepicker .react-datepicker__header .react-datepicker__day-name, .react-datepicker .react-datepicker__header .react-datepicker__current-month {
    color: var(--font-color); }

.react-datepicker .react-datepicker__today-button {
  background-color: var(--grey-background-color); }

.react-datepicker .react-datepicker__triangle {
  border-top-color: var(--grey-background-color) !important; }

.info-box {
  padding: 10px;
  font-size: 0.8rem;
  color: var(--font-color);
  text-align: left;
  margin-bottom: 5px;
  margin-right: 20px;
  background-color: var(--messages-background-color);
  min-height: 38px;
  border-radius: 4px; }
  .info-box.warning {
    background-color: var(--warning-background-color); }
  .info-box.error {
    background-color: var(--error-background-color); }
  .info-box.plain {
    font-size: 1rem;
    background-color: transparent; }
  .info-box .badge-pill {
    margin-right: 10px; }

.smiley-selector {
  display: flex;
  justify-content: space-evenly;
  margin: 0 aut0;
  max-width: 300px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 48px;
  cursor: pointer; }

.smiley {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.7; }
  .smiley.happy.selected {
    opacity: 1;
    color: var(--ok-color); }
  .smiley.frown.selected {
    opacity: 1;
    color: var(--warning-color); }
  .smiley.sad.selected {
    opacity: 1;
    color: var(--error-color); }
  .smiley:focus {
    outline: none; }
