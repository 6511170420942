.projects, .assignments {
  position: relative; }
  .projects .grid, .assignments .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    padding: 10px 0; }
  .projects a:hover, .assignments a:hover {
    text-decoration: none; }
  .projects .project, .projects .assignment, .assignments .project, .assignments .assignment {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 130px auto;
    height: auto;
    overflow: hidden;
    padding: 8px;
    background-color: var(--grey-background-color);
    position: relative;
    overflow: hidden; }
    .projects .project .details, .projects .assignment .details, .assignments .project .details, .assignments .assignment .details {
      padding: 4px;
      overflow: hidden; }
    .projects .project h5, .projects .project .name, .projects .assignment h5, .projects .assignment .name, .assignments .project h5, .assignments .project .name, .assignments .assignment h5, .assignments .assignment .name {
      margin: 0;
      text-transform: uppercase;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .projects .project .split-line, .projects .assignment .split-line, .assignments .project .split-line, .assignments .assignment .split-line {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .projects .project .headline, .projects .assignment .headline, .assignments .project .headline, .assignments .assignment .headline {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .projects .project .thumbnail, .projects .assignment .thumbnail, .assignments .project .thumbnail, .assignments .assignment .thumbnail {
      padding: 4px; }
      .projects .project .thumbnail a, .projects .assignment .thumbnail a, .assignments .project .thumbnail a, .assignments .assignment .thumbnail a {
        position: relative;
        height: 40px;
        width: 40px;
        display: block; }
        .projects .project .thumbnail a .profile-thumbnail, .projects .assignment .thumbnail a .profile-thumbnail, .assignments .project .thumbnail a .profile-thumbnail, .assignments .assignment .thumbnail a .profile-thumbnail {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1; }
        .projects .project .thumbnail a .profile-thumnail-badge, .projects .assignment .thumbnail a .profile-thumnail-badge, .assignments .project .thumbnail a .profile-thumnail-badge, .assignments .assignment .thumbnail a .profile-thumnail-badge {
          position: absolute;
          top: 0;
          right: -5px;
          z-index: 10; }
    .projects .project .summary, .projects .assignment .summary, .assignments .project .summary, .assignments .assignment .summary {
      height: 52px;
      overflow: hidden; }
    .projects .project .strength, .projects .assignment .strength, .assignments .project .strength, .assignments .assignment .strength {
      padding: 4px; }
    .projects .project:hover, .projects .assignment:hover, .assignments .project:hover, .assignments .assignment:hover {
      background-color: var(--background-colour-light1);
      box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1); }
    .projects .project.add-new, .projects .assignment.add-new, .assignments .project.add-new, .assignments .assignment.add-new {
      border: 2px dashed var(--border-color);
      grid-template-columns: 1fr; }
    .projects .project .add-label, .projects .assignment .add-label, .assignments .project .add-label, .assignments .assignment .add-label {
      color: var(--font-minor-color);
      text-align: center;
      width: 100%;
      margin: auto auto; }
    .projects .project.selected, .projects .assignment.selected, .assignments .project.selected, .assignments .assignment.selected {
      border: 2px dashed var(--feature-colour-light-1); }
    .projects .project .controls, .projects .assignment .controls, .assignments .project .controls, .assignments .assignment .controls {
      position: absolute;
      top: 4px;
      right: 0;
      display: none; }
    .projects .project .tools, .projects .assignment .tools, .assignments .project .tools, .assignments .assignment .tools {
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0;
      display: none;
      background-color: var(--background-colour-light1);
      padding: 4px; }
    .projects .project:hover .controls, .projects .project:hover .tools, .projects .assignment:hover .controls, .projects .assignment:hover .tools, .assignments .project:hover .controls, .assignments .project:hover .tools, .assignments .assignment:hover .controls, .assignments .assignment:hover .tools {
      display: block; }
    .projects .project:hover .tools, .projects .assignment:hover .tools, .assignments .project:hover .tools, .assignments .assignment:hover .tools {
      box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1); }
    .projects .project .user-tags, .projects .assignment .user-tags, .assignments .project .user-tags, .assignments .assignment .user-tags {
      height: 40px;
      overflow: hidden; }
    .projects .project .name, .projects .assignment .name, .assignments .project .name, .assignments .assignment .name {
      text-transform: none; }
    .projects .project .footer, .projects .assignment .footer, .assignments .project .footer, .assignments .assignment .footer {
      min-height: 20px;
      padding: 4px; }
    .projects .project .header, .projects .assignment .header, .assignments .project .header, .assignments .assignment .header {
      padding: 4px;
      display: flex; }
      .projects .project .header > *, .projects .assignment .header > *, .assignments .project .header > *, .assignments .assignment .header > * {
        flex: 1 1 auto; }
      .projects .project .header .info, .projects .assignment .header .info, .assignments .project .header .info, .assignments .assignment .header .info {
        text-align: right;
        display: flex;
        justify-content: flex-end; }
    .projects .project .project-date label, .projects .assignment .project-date label, .assignments .project .project-date label, .assignments .assignment .project-date label {
      margin: 0;
      font-size: 0.9rem; }
    .projects .project .project-date .date, .projects .assignment .project-date .date, .assignments .project .project-date .date, .assignments .assignment .project-date .date {
      font-weight: 300;
      font-size: 0.9rem; }
    .projects .project .brief, .projects .assignment .brief, .assignments .project .brief, .assignments .assignment .brief {
      background-color: white;
      padding: 8px;
      font-size: 0.8rem;
      color: var(--font-minor-color) !important; }
      .projects .project .brief .headline, .projects .assignment .brief .headline, .assignments .project .brief .headline, .assignments .assignment .brief .headline {
        display: flex; }
        .projects .project .brief .headline > *, .projects .assignment .brief .headline > *, .assignments .project .brief .headline > *, .assignments .assignment .brief .headline > * {
          flex: 1 1 auto; }
        .projects .project .brief .headline .terms, .projects .assignment .brief .headline .terms, .assignments .project .brief .headline .terms, .assignments .assignment .brief .headline .terms {
          justify-content: flex-end;
          text-align: right; }
    .projects .project.my-assignment, .projects .assignment.my-assignment, .assignments .project.my-assignment, .assignments .assignment.my-assignment {
      grid-template-rows: auto minmax(100px, auto) auto; }
    .projects .project .profile-photo, .projects .assignment .profile-photo, .assignments .project .profile-photo, .assignments .assignment .profile-photo {
      height: 64px;
      width: 64px;
      margin-left: 20px; }
  .projects .assignment.expanded, .assignments .assignment.expanded {
    min-height: 400px; }
  .projects .project-type, .assignments .project-type {
    display: block;
    /*grid-template-columns: 1fr;
		grid-template-rows: 4fr 1fr;*/ }
    .projects .project-type .name, .assignments .project-type .name {
      text-transform: none; }
  @media (max-width: 1200px) {
    .projects .grid, .assignments .grid {
      grid-template-columns: 1fr 1fr 1fr; } }
  @media (max-width: 991px) {
    .projects .grid, .assignments .grid {
      grid-template-columns: 1fr 1fr; } }
  @media (max-width: 576px) {
    .projects .grid, .assignments .grid {
      grid-template-columns: 1fr; } }

.project .status, .assignment .status {
  grid-area: status;
  display: inline-block;
  text-transform: lowercase;
  font-size: var(--minor-font-size);
  text-align: center;
  color: white;
  background-color: var(--success-color);
  padding: 4px 16px; }
  .project .status::first-letter, .assignment .status::first-letter {
    text-transform: uppercase; }
  .project .status.FINISHED, .project .status.CANCELLED, .assignment .status.FINISHED, .assignment .status.CANCELLED {
    background-color: var(--background-colour-dark1);
    color: var(--font-color); }
  .project .status.PENDING, .project .status.DRAFT, .assignment .status.PENDING, .assignment .status.DRAFT {
    background-color: var(--warning-color); }
  .project .status.SUBMITTED, .assignment .status.SUBMITTED {
    background-color: var(--feature-colour); }
  .project .status.REJECTED, .assignment .status.REJECTED {
    background-color: var(--error-color); }
  .project .status::before, .assignment .status::before {
    display: inline-block; }

/* override general settings above */
.assignments {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 30px; }
  .assignments h4 {
    padding-top: 20px; }
  .assignments.data-items .grid, .assignments .grid {
    grid-template-columns: 1fr; }
  .assignments .assignment {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 		1.5fr 		6fr 	1fr 			3.5fr 	3fr 		3fr 	3fr 		3fr 	3fr 	3fr 	3fr;
    grid-template-areas: "index 		thumbnail 	name 	status-icons 	status 	startDate 	endDate duration 	rate 	effort 	booked 	billable";
    height: auto;
    overflow: hidden;
    padding: 0px;
    background-color: white;
    position: relative;
    overflow: hidden;
    border-top: 1px solid var(--border-color);
    align-items: center;
    min-height: 60px; }
    @media (max-width: 991px) {
      .assignments .assignment {
        grid-template-areas: "thumbnail name name name name" "status-icons status status status status" "effort effort rate booked billable";
        grid-template-columns: 0.5fr 0.5fr 1fr 1fr 1fr; }
        .assignments .assignment .start-date, .assignments .assignment .end-date, .assignments .assignment .duration, .assignments .assignment .index, .assignments .assignment .brief-pills {
          display: none; } }
    .assignments .assignment .thumbnail {
      float: left;
      padding: 4px;
      grid-area: thumbnail; }
      .assignments .assignment .thumbnail .profile-thumbnail {
        height: 40px;
        width: 40px; }
      .assignments .assignment .thumbnail.project-manager .profile-thumbnail {
        border-color: var(--feature-colour); }
    .assignments .assignment .index {
      grid-area: index;
      text-align: center; }
    .assignments .assignment .name {
      grid-area: name;
      padding: 4px;
      display: flex;
      flex-direction: column; }
      .assignments .assignment .name h5 {
        text-transform: uppercase;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0; }
    .assignments .assignment .headline {
      grid-area: headline;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      align-self: self-start;
      color: var(--font-minor-color);
      font-size: var(--minor-font-size); }
    .assignments .assignment .status {
      display: inline-block;
      text-transform: lowercase;
      font-size: var(--minor-font-size);
      text-align: center;
      color: white;
      background-color: var(--success-color);
      padding: 4px 16px;
      grid-area: status; }
      .assignments .assignment .status::first-letter {
        text-transform: uppercase; }
      .assignments .assignment .status.FINISHED, .assignments .assignment .status.CANCELLED {
        background-color: var(--background-colour-dark1);
        color: var(--font-color); }
      .assignments .assignment .status.PENDING, .assignments .assignment .status.DRAFT {
        background-color: var(--warning-color); }
      .assignments .assignment .status.SUBMITTED {
        background-color: var(--feature-colour); }
      .assignments .assignment .status.REJECTED {
        background-color: var(--error-color); }
      .assignments .assignment .status::before {
        display: inline-block; }
      .assignments .assignment .status.status-brief {
        text-transform: none;
        cursor: pointer; }
    .assignments .assignment .status-icons {
      grid-area: status-icons;
      text-align: center; }
    .assignments .assignment .brief {
      font-size: var(--minor-font-size);
      color: var(--font-minor-color);
      padding: 8px 0 0 0;
      background-color: transparent; }
    .assignments .assignment .start-date {
      grid-area: startDate;
      text-align: center; }
    .assignments .assignment .end-date {
      grid-area: endDate;
      text-align: center; }
    .assignments .assignment .duration {
      grid-area: duration;
      text-align: center; }
    .assignments .assignment .rate {
      grid-area: rate;
      text-align: right; }
    .assignments .assignment .booked {
      grid-area: booked;
      text-align: center; }
    .assignments .assignment .billable {
      grid-area: billable;
      text-align: right; }
    .assignments .assignment .effort {
      grid-area: effort;
      text-align: center; }
    .assignments .assignment:hover {
      background-color: var(--background-colour-light1);
      box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1); }
    .assignments .assignment.add-new {
      border: unset;
      border-top: 1px solid var(--border-color);
      grid-template-columns: 1fr;
      text-align: left; }
    .assignments .assignment .add-label {
      color: var(--font-minor-color);
      text-align: center;
      width: 100%;
      margin: auto auto; }
    .assignments .assignment.selected {
      border: 2px dashed var(--feature-colour-light-1); }
    .assignments .assignment .controls {
      position: absolute;
      top: 4px;
      right: 0;
      display: none; }
    .assignments .assignment .tools {
      width: auto;
      position: absolute;
      right: 0;
      top: 0;
      left: unset;
      display: none;
      background-color: var(--background-colour-light1);
      padding: 4px; }
    .assignments .assignment:hover .controls, .assignments .assignment:hover .tools {
      display: flex; }
    .assignments .assignment:hover .tools {
      box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1); }
    .assignments .assignment .units {
      font-size: var(--minor-font-size);
      color: var(--font-minor-color);
      display: block;
      font-weight: normal; }
    .assignments .assignment .user-tags {
      height: 40px;
      overflow: hidden; }
    .assignments .assignment.header {
      font-size: 1rem;
      text-transform: unset;
      font-weight: bold;
      grid-template-areas: 'index thumbnail name status-icons status startDate endDate duration rate effort booked billable'; }
      .assignments .assignment.header .name {
        text-transform: unset;
        font-size: 1rem;
        font-weight: unset;
        align-self: unset; }
      .assignments .assignment.header .status {
        background-color: unset;
        color: unset;
        font-size: 1rem; }

.project-edit .project-header {
  position: relative; }

.project-edit .project-details {
  background-color: var(--grey-background-color);
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap; }
  .project-edit .project-details .project-info {
    flex-grow: 3;
    padding-bottom: 8px; }
  .project-edit .project-details .project-financials {
    flex-grow: 1;
    background-color: white;
    text-align: center;
    max-width: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr; }
    .project-edit .project-details .project-financials .project-kpi {
      font-size: 1.5rem;
      line-height: 1.5;
      padding: 8px;
      border-bottom: 1px dashed var(--border-color); }
      .project-edit .project-details .project-financials .project-kpi label {
        display: block;
        font-size: var(--minor-font-size);
        color: var(--font-minor-color);
        font-weight: normal; }
      .project-edit .project-details .project-financials .project-kpi.variance.bad {
        color: var(--error-color); }
      .project-edit .project-details .project-financials .project-kpi.variance.ok {
        color: var(--ok-color); }

.project-edit label {
  font-weight: bold;
  margin: 0; }

.project-edit .date, .project-edit .setting-value {
  font-weight: 300; }
