/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	B O O T S T R A P   O V E R R I D E S
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.input-group .input-group-text {
  padding: 2px; }

.input-group .input-group-prepend .input-group-text, .input-group .input-group-append .input-group-text {
  padding: 0 10px; }

.navbar {
  border: none;
  background-color: var(--feature-colour);
  background-image: none;
  height: 60px;
  z-index: 100; }
  .navbar ul {
    background-color: var(--feature-colour); }
    .navbar ul li {
      border-right: 1px solid var(--feature-colour-light-2); }
    .navbar ul li > a {
      color: white !important;
      padding-top: 20px;
      padding-bottom: 20px;
      height: 60px;
      min-width: 70px;
      text-align: center; }
    .navbar ul .active > a {
      background-color: var(--feature-colour-light-1);
      background-image: none;
      box-shadow: none; }
      .navbar ul .active > a:hover, .navbar ul .active > a:focus {
        background-color: var(--feature-colour-light-1); }
  .navbar .navbar-brand {
    color: white;
    background: url("../../../public/media/logo-transparentwhite.svg") no-repeat center center;
    background-size: 211px;
    width: 270px;
    height: 37px; }
  .navbar .search-form {
    margin-right: 30px; }
    .navbar .search-form a.btn, .navbar .search-form button.btn, .navbar .search-form .input-group-text {
      background-color: var(--feature-colour-light-1);
      color: white;
      border: none;
      margin: 0; }
      .navbar .search-form a.btn.glyphicon-button, .navbar .search-form button.btn.glyphicon-button, .navbar .search-form .input-group-text.glyphicon-button {
        padding-right: 0; }
    .navbar .search-form input {
      border: none; }
  .navbar a.btn {
    color: white !important;
    line-height: 1.6;
    box-shadow: none; }

.navbar-photo {
  display: block;
  font-size: 10px;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 2px solid var(--grey-background-color);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  text-align: center; }

.logo {
  display: block;
  color: white;
  background: url("../../../public/media/logo-greygrey.svg") no-repeat left center;
  background-size: 211px;
  width: 270px;
  height: 37px; }

.breadcrumb {
  background-color: transparent;
  padding-left: 0; }

.dropdown-menu.cut-width {
  overflow: hidden;
  max-width: 100%; }

.btn {
  min-height: 41px; }

.btn-warning, .btn-success {
  border-radius: 100px;
  padding: 10px 20px; }

.btn-default, button.btn-default {
  background-color: var(--feature-colour);
  color: white !important;
  border: none;
  border-radius: 100px;
  padding: 10px 20px; }
  .btn-default:hover, button.btn-default:hover {
    background-color: var(--feature-colour-light-1);
    color: white; }

.btn:focus, button:focus {
  outline: 0;
  box-shadow: none; }

.btn:disabled {
  opacity: 0.4;
  cursor: not-allowed; }

.btn-icon {
  text-align: center;
  margin-right: 0.25rem; }
  .btn-icon > span {
    display: block;
    font-size: 2rem;
    margin: 0 auto !important; }
  .btn-icon:hover {
    background-color: var(--feature-colour-light-2); }

table {
  background: white; }

.progress {
  position: relative; }
  .progress .progress-bar {
    background-color: var(--feature-colour-light-1); }

.card {
  border-radius: 0;
  border: none;
  background-color: var(--grey-background-color);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  margin-top: 10px; }
  .card.tall {
    min-height: 500px; }

.card .card {
  box-shadow: none; }

.tiles {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 20px 0; }
  .tiles .tile {
    border: none;
    padding: 10px;
    min-height: 100px;
    position: relative;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: var(--grey-background-color);
    padding: 10px;
    min-height: 100px;
    overflow: hidden; }
    .tiles .tile .controls {
      top: 10px;
      display: none; }
    .tiles .tile .tools {
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0;
      display: none;
      background-color: var(--background-colour-light1);
      padding: 4px; }
    .tiles .tile:hover {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1); }
    .tiles .tile:hover .controls {
      display: block; }
    .tiles .tile:hover .tools {
      display: block;
      box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1); }
    .tiles .tile.add-new {
      border: 2px dashed var(--border-color);
      display: flex; }
    .tiles .tile .add-label {
      display: block;
      color: var(--font-minor-color);
      text-align: center;
      width: 100%;
      margin: auto auto; }
    .tiles .tile h5 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;
      margin-bottom: 0; }
    .tiles .tile .headline {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px; }
    .tiles .tile .summary {
      height: 3rem;
      overflow: hidden; }
    .tiles .tile.recent-talent h5 {
      max-width: 150px; }
    .tiles .tile.recent-talent .headline {
      max-width: 150px; }

.controls-container {
  display: flex;
  min-height: 40px;
  padding: 4px;
  align-items: center; }

.controls {
  float: right;
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 0 5px;
  text-align: right;
  z-index: 1000; }
  .controls .glyphicons {
    line-height: 0.5; }

.glyph-with-text .glyphicons {
  line-height: 0.8; }

.columns {
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px; }
  .columns.thin-left {
    grid-template-columns: 1fr 3fr; }
  .columns.equal-three {
    grid-template-columns: 1fr 1fr 1fr; }

.main-content {
  margin-top: 20px;
  min-height: 600px; }
  .main-content .panel {
    margin-left: 0; }

.side-column {
  padding: 10px;
  min-height: 500px; }
  .side-column margin
.panel {
    margin-left: 0; }

.main-column {
  padding: 10px;
  min-height: 500px; }

.enum-type {
  display: inline-block;
  text-transform: lowercase; }
  .enum-type.DRAFT, .enum-type.ERROR {
    color: var(--error-color); }
  .enum-type.REVIEW, .enum-type.WARNING, .enum-type.STANDBY {
    color: var(--warning-color); }
  .enum-type:first-letter {
    text-transform: uppercase; }
  .enum-type.block {
    display: block; }

footer {
  min-height: 200px;
  background-color: var(--background-colour-light1);
  text-align: center;
  padding: 20px; }
  footer .footer-links {
    overflow: hidden;
    width: 100%;
    padding-top: 20px;
    padding-left: 40px; }
  footer nav {
    float: left;
    width: 200px;
    height: 200px;
    text-align: left; }
    footer nav ul {
      list-style: none;
      margin: 0;
      padding: 0; }
  footer .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin: 20px 0; }
    @media (max-width: 576px) {
      footer .grid {
        grid-template-columns: 1fr; } }

.bootstrap-typeahead-menu li {
  padding: 4px; }

.bootstrap-typeahead-menu li.active {
  background-color: var(--feature-colour);
  color: white; }

rbt-highlight-text.mark, mark.rbt-highlight-text {
  padding: 0; }

.pill {
  background-color: var(--feature-colour);
  color: white;
  padding: 4px 10px;
  margin-bottom: 4px;
  border-radius: 5px;
  margin-left: 5px;
  display: inline-block; }
  .pill > span {
    margin-left: 10px; }
  .pill .glyphicons:before {
    padding: 0; }
  .pill.alert {
    background-color: var(--error-background-color);
    color: var(--error-color) !important; }
  .pill > .glyphicons {
    padding-top: 3px; }
  .pill .badge {
    background-color: rgba(255, 255, 255, 0.2); }
    .pill .badge a {
      color: white; }
  .pill.new {
    background-color: var(--feature-colour-dark-1); }
  .pill.search-criteria {
    background-color: var(--background-colour-light1);
    color: var(--font-minor-color); }

.btn-group.pill {
  padding: 0;
  background-color: unset; }
  .btn-group.pill > .btn {
    border-radius: .2rem;
    padding: .25rem .5rem; }
  .btn-group.pill.alert {
    background-color: var(--error-background-color) !important;
    color: var(--error-color) !important; }

.results-table {
  position: relative; }
  .results-table .controls {
    top: 0px; }
  .results-table h4 {
    padding-bottom: 4px; }

.dropdown-submenu {
  margin-left: 100%; }

.spinner-container {
  height: 300px;
  margin: 50px auto;
  padding-top: 50px;
  background: url("../../../public/media/logo-greygrey.svg") no-repeat top center;
  background-size: 200px;
  width: 270px; }

@media (max-width: 576px) {
  .navbar .navbar-collapse {
    background-color: var(--feature-colour);
    padding: 15px;
    text-align: left; }
    .navbar .navbar-collapse li {
      border: none; }
    .navbar .navbar-collapse .search-form, .navbar .navbar-collapse a.btn {
      display: none; }
  .controls {
    right: 0;
    padding: 0 0 5px 0; }
  .controls-container .controls {
    position: relative; } }

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	E R R O R S
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.errors, .messages {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 400px;
  min-height: 100px;
  padding: 20px 40px 20px 10px;
  box-shadow: 0px 0 3px rgba(0, 0, 0, 0.5);
  background-color: var(--grey-background-color);
  color: var(--font-color);
  border-radius: 4px;
  border-top: 6px solid var(--error-color);
  z-index: 2000;
  font-weight: bold; }
  .errors a, .errors button, .messages a, .messages button {
    top: 5px;
    right: 5px;
    position: absolute;
    text-align: center;
    color: var(--error-color); }
  .errors.errors-type-inline, .messages.errors-type-inline {
    position: relative;
    box-shadow: none; }
    .errors.errors-type-inline .error-close, .messages.errors-type-inline .error-close {
      display: none; }
  .errors.errors-1 li, .messages.errors-1 li {
    list-style: none;
    padding: 10px; }
  .errors ul.single, .messages ul.single {
    list-style-type: none;
    padding-left: 5px; }

.messages {
  top: unset;
  bottom: 10px;
  min-height: 60px;
  border-top: 6px solid var(--feature-colour); }
  .messages a {
    color: var(--messages-color); }
  .messages.messages-type-inline {
    position: relative;
    box-shadow: none; }
    .messages.messages-type-inline .message-close {
      display: none; }
  .messages.messages-1 li {
    list-style: none;
    padding: 10px; }

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	C O N S O L E   L A Y O U T
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.console-layout {
  min-height: 800px; }
  .console-layout .console-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 80px;
    height: 100%;
    padding-top: 100px;
    background-color: var(--grey-color);
    text-align: center;
    padding-top: 20px;
    box-shadow: 0px 0 3px rgba(0, 0, 0, 0.1);
    z-index: 1000; }
    .console-layout .console-nav .nav-logo {
      width: 100%;
      height: 64px;
      display: block;
      position: absolute;
      background: var(--grey-color) url("../../../public/media/favicon.svg") no-repeat center 0;
      background-size: 40px; }
    .console-layout .console-nav ul.nav-menu {
      margin-top: 80px;
      padding: 0;
      background: var(--grey-color);
      list-style-type: none; }
      .console-layout .console-nav ul.nav-menu li {
        position: relative; }
        .console-layout .console-nav ul.nav-menu li label {
          display: block;
          font-weight: bold; }
        .console-layout .console-nav ul.nav-menu li > a, .console-layout .console-nav ul.nav-menu li > button, .console-layout .console-nav ul.nav-menu li .nav-menu-item {
          text-decoration: none;
          display: block;
          min-height: 60px;
          padding: 10px 0;
          font-size: var(--minor-font-size); }
        .console-layout .console-nav ul.nav-menu li .hover-menu {
          display: block;
          position: absolute;
          left: 80px;
          bottom: 0;
          min-height: 40px;
          background-color: white;
          padding: 0;
          transition: width 0.25s ease;
          width: 0;
          opacity: 0;
          overflow: hidden;
          width: 0;
          height: 0;
          z-index: 1000; }
        .console-layout .console-nav ul.nav-menu li:hover {
          background-color: white; }
          .console-layout .console-nav ul.nav-menu li:hover .hover-menu {
            width: 250px;
            opacity: 1;
            border-radius: 0 4px 4px 0;
            box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1);
            border-left: 1px solid var(--border-color);
            padding: 10px;
            height: auto; }
        .console-layout .console-nav ul.nav-menu li.active {
          background-color: white; }
        .console-layout .console-nav ul.nav-menu li .icon {
          font-size: 2rem; }
    .console-layout .console-nav .navbar-toggler {
      position: absolute;
      right: 10px;
      top: 20px;
      display: none; }
      .console-layout .console-nav .navbar-toggler .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
    .console-layout .console-nav .user-menu {
      bottom: 0;
      left: 0;
      position: fixed;
      background: var(--grey-color);
      display: block;
      width: 80px; }
      .console-layout .console-nav .user-menu ul.nav-menu {
        margin-top: 0; }
      .console-layout .console-nav .user-menu .navbar-photo {
        display: inline-block; }
  .console-layout .console-body {
    margin-left: 80px;
    padding: 10px 50px;
    min-height: 800px; }
  .console-layout.unauthenticated-layout .console-body {
    margin-left: 0px; }
  .console-layout .secondary-nav {
    right: 10px; }
  .console-layout .search-form, .console-layout .search {
    margin: 0;
    min-width: 100px; }
    .console-layout .search-form a.btn, .console-layout .search-form button.btn, .console-layout .search-form .hover-menu .input-group-text, .console-layout .search a.btn, .console-layout .search button.btn, .console-layout .search .hover-menu .input-group-text {
      background-color: var(--feature-colour-light-1);
      color: white;
      border: none;
      margin: 0; }
    .console-layout .search-form button.input-group-text, .console-layout .search button.input-group-text {
      border: none;
      background-color: transparent;
      filter: invert(0.7); }
    .console-layout .search-form input, .console-layout .search input {
      border-radius: 8px;
      border: none;
      background-color: var(--grey-background-color);
      padding: 4px 8px;
      font-size: 1rem;
      color: var(--font-minor-color); }
    .console-layout .search-form .input-group > .form-control:not(:last-child), .console-layout .search .input-group > .form-control:not(:last-child) {
      border-radius: 8px; }
  .console-layout .console-top-nav {
    padding: 20px 0;
    overflow: hidden; }
    .console-layout .console-top-nav ul {
      padding: 0;
      list-style-type: none;
      display: inline-flex;
      flex-direction: row; }
      .console-layout .console-top-nav ul li a, .console-layout .console-top-nav ul li button {
        font-weight: bold;
        font-size: 1rem;
        margin: 5px 10px;
        padding: 5px 0; }
        .console-layout .console-top-nav ul li a:hover, .console-layout .console-top-nav ul li button:hover {
          text-decoration: none;
          border-bottom: 3px solid var(--feature-colour); }
      .console-layout .console-top-nav ul .active a, .console-layout .console-top-nav ul .active button {
        border-bottom: 2px solid var(--feature-colour); }
      .console-layout .console-top-nav ul li:first-child a {
        padding-left: 0;
        margin-left: 0; }
      .console-layout .console-top-nav ul li.dropdown:hover {
        background-color: white; }
        .console-layout .console-top-nav ul li.dropdown:hover .dropdown-menu {
          display: block;
          z-index: 10000; }
    .console-layout .console-top-nav .right {
      float: right; }
      .console-layout .console-top-nav .right a, .console-layout .console-top-nav .right button {
        color: var(--font-minor-color);
        font-weight: 300;
        display: flex;
        align-items: center;
        border-bottom: 3px solid transparent; }
      .console-layout .console-top-nav .right .icon {
        font-size: 1.2rem; }
      .console-layout .console-top-nav .right .search a {
        display: inline-block; }
    .console-layout .console-top-nav .nav-logo {
      height: 64px;
      display: block;
      position: absolute;
      width: 200px;
      background: transparent url("../../../public/media/logo-blueblue.svg") no-repeat;
      background-size: 180px; }
    .console-layout .console-top-nav .splash-menu {
      float: right; }
  .console-layout .data-grid .data-item {
    min-height: 120px;
    height: auto;
    overflow: hidden;
    padding: 8px;
    background-color: var(--grey-background-color);
    position: relative;
    overflow: hidden;
    grid-template-rows: 130px auto;
    display: grid; }
    .console-layout .data-grid .data-item.add-new, .console-layout .data-grid .data-item.no-data {
      border: none;
      grid-template-columns: 1fr;
      background-color: white; }
    .console-layout .data-grid .data-item .add-label, .console-layout .data-grid .data-item .no-data-label {
      color: var(--font-minor-color);
      text-align: center;
      width: 100%;
      margin: auto auto; }
    .console-layout .data-grid .data-item .no-data-label {
      text-align: left; }
  @media (max-width: 576px) {
    .console-layout .console-nav {
      position: relative;
      width: 100%;
      height: 80px;
      background-position: 10px center; }
      .console-layout .console-nav .nav-logo {
        width: 200px;
        background-image: url("../../../public/media/logo-blueblue.svg");
        background-size: 180px;
        height: 60px;
        background-position: 10px 10px; }
      .console-layout .console-nav ul.nav-menu {
        display: none;
        margin: 50px auto 0 auto;
        right: 0;
        width: 80%;
        border-radius: 0 0 4px 4px;
        box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1);
        border-top: 1px solid var(--border-color);
        height: 0;
        overflow: hidden; }
        .console-layout .console-nav ul.nav-menu.show-menu {
          display: block;
          height: auto; }
        .console-layout .console-nav ul.nav-menu li .hover-menu {
          opacity: 1;
          position: relative;
          width: 100%;
          left: 0;
          border: none;
          height: auto;
          padding: 10px;
          background-color: var(--grey-color); }
          .console-layout .console-nav ul.nav-menu li .hover-menu .minor {
            display: none; }
        .console-layout .console-nav ul.nav-menu li:hover .hover-menu {
          width: auto;
          opacity: 1;
          border: none;
          border-radius: 0;
          box-shadow: none; }
      .console-layout .console-nav .navbar-toggler {
        display: block; }
      .console-layout .console-nav .user-menu {
        position: relative;
        width: 80%;
        margin: 0 auto; }
    .console-layout .console-body {
      margin-left: 0;
      padding: 10px; }
    .console-layout .console-top-nav .right {
      display: none; }
    .console-layout .console-top-nav ul {
      display: block; }
      .console-layout .console-top-nav ul li {
        display: inline-block; }
        .console-layout .console-top-nav ul li > a, .console-layout .console-top-nav ul li > button {
          margin: 5px 10px;
          padding: 0; } }

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	S P L A S H
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.splash .carousel, .join .carousel, .unauthenticated-layout .carousel {
  margin-bottom: 8rem;
  background-color: black; }

.splash .carousel-inner, .splash .carousel-item, .join .carousel-inner, .join .carousel-item, .unauthenticated-layout .carousel-inner, .unauthenticated-layout .carousel-item {
  height: calc(100vh); }

.splash nav, .join nav, .unauthenticated-layout nav {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  height: 60px; }
  .splash nav .logo, .join nav .logo, .unauthenticated-layout nav .logo {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    background: url("../../../public/media/logo-transparentwhite.svg") no-repeat center center;
    background-size: 211px;
    width: 270px;
    height: 37px; }
  .splash nav a, .splash nav button, .join nav a, .join nav button, .unauthenticated-layout nav a, .unauthenticated-layout nav button {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.25); }

.splash .carousel-caption, .join .carousel-caption, .unauthenticated-layout .carousel-caption {
  top: 100px;
  width: 50%;
  text-align: left; }
  .splash .carousel-caption p, .join .carousel-caption p, .unauthenticated-layout .carousel-caption p {
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    padding: 20px 0; }
  .splash .carousel-caption h3, .join .carousel-caption h3, .unauthenticated-layout .carousel-caption h3 {
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.25); }

.splash .splash-menu, .join .splash-menu, .unauthenticated-layout .splash-menu {
  list-style: none;
  flex-direction: row;
  display: flex;
  float: right;
  position: absolute;
  top: 0;
  right: 20px; }
  .splash .splash-menu li, .join .splash-menu li, .unauthenticated-layout .splash-menu li {
    padding: 10px;
    margin-top: 10px; }
    .splash .splash-menu li a, .splash .splash-menu li button, .join .splash-menu li a, .join .splash-menu li button, .unauthenticated-layout .splash-menu li a, .unauthenticated-layout .splash-menu li button {
      color: white;
      font-weight: bold;
      font-size: 16px; }

.splash .value-prop, .join .value-prop, .unauthenticated-layout .value-prop {
  margin: 0 auto;
  padding: 40px 80px;
  text-align: left; }
  .splash .value-prop .col-md-4, .join .value-prop .col-md-4, .unauthenticated-layout .value-prop .col-md-4 {
    text-align: center; }
    .splash .value-prop .col-md-4 img, .join .value-prop .col-md-4 img, .unauthenticated-layout .value-prop .col-md-4 img {
      margin-bottom: 20px;
      opacity: 0.75;
      object-fit: cover; }
    .splash .value-prop .col-md-4 .value-prop-detail, .join .value-prop .col-md-4 .value-prop-detail, .unauthenticated-layout .value-prop .col-md-4 .value-prop-detail {
      min-height: 150px; }
  .splash .value-prop p, .join .value-prop p, .unauthenticated-layout .value-prop p {
    font-size: 18px; }
  .splash .value-prop .quote, .join .value-prop .quote, .unauthenticated-layout .value-prop .quote {
    font-size: 22px;
    font-style: italic;
    padding: 20px 0px; }
  .splash .value-prop.shade, .join .value-prop.shade, .unauthenticated-layout .value-prop.shade {
    background-color: var(--background-colour-light1); }
  .splash .value-prop .well-padded, .join .value-prop .well-padded, .unauthenticated-layout .value-prop .well-padded {
    padding: 60px 0px; }
    .splash .value-prop .well-padded img, .join .value-prop .well-padded img, .unauthenticated-layout .value-prop .well-padded img {
      margin-bottom: 10px; }
  .splash .value-prop.row.title, .join .value-prop.row.title, .unauthenticated-layout .value-prop.row.title {
    padding-bottom: 10px; }
  .splash .value-prop .key-features, .join .value-prop .key-features, .unauthenticated-layout .value-prop .key-features {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 999px;
    margin: 0 auto; }
    .splash .value-prop .key-features .key-feature, .join .value-prop .key-features .key-feature, .unauthenticated-layout .value-prop .key-features .key-feature {
      max-width: 200px;
      padding: 20px;
      margin: 10px;
      border-radius: 4px;
      border: 1px solid var(--border-color); }
      .splash .value-prop .key-features .key-feature h3, .join .value-prop .key-features .key-feature h3, .unauthenticated-layout .value-prop .key-features .key-feature h3 {
        font-size: 2rem;
        padding-bottom: 10px; }
      .splash .value-prop .key-features .key-feature:hover, .join .value-prop .key-features .key-feature:hover, .unauthenticated-layout .value-prop .key-features .key-feature:hover {
        box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1); }
      .splash .value-prop .key-features .key-feature .info, .join .value-prop .key-features .key-feature .info, .unauthenticated-layout .value-prop .key-features .key-feature .info {
        font-size: 0.9rem; }

.splash small, .join small, .unauthenticated-layout small {
  font-size: 16px; }

.splash h3, .join h3, .unauthenticated-layout h3 {
  font-size: 3rem; }

.splash .justify-content-md-center, .join .justify-content-md-center, .unauthenticated-layout .justify-content-md-center {
  text-align: center; }

.splash .btn-default, .join .btn-default, .unauthenticated-layout .btn-default {
  padding: 10px 20px; }

.splash p {
  font-size: 1.3rem;
  line-height: 2rem; }

.account-joined .value-prop {
  margin: 0 auto;
  padding: 40px 80px;
  text-align: left; }
  .account-joined .value-prop .col-md-4 {
    text-align: center; }
    .account-joined .value-prop .col-md-4 img {
      margin-bottom: 20px;
      opacity: 0.75;
      object-fit: cover; }
    .account-joined .value-prop .col-md-4 .value-prop-detail {
      min-height: 150px; }
  .account-joined .value-prop p {
    font-size: 18px; }
  .account-joined .value-prop .quote {
    font-size: 22px;
    font-style: italic;
    padding: 20px 0px; }
  .account-joined .value-prop.shade {
    background-color: var(--background-colour-light1); }
  .account-joined .value-prop .well-padded {
    padding: 60px 0px; }
    .account-joined .value-prop .well-padded img {
      margin-bottom: 10px; }
  .account-joined .value-prop.row.title {
    padding-bottom: 10px; }
  .account-joined .value-prop .key-features {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .account-joined .value-prop .key-features .key-feature {
      max-width: 200px;
      padding: 20px;
      margin: 10px;
      border-radius: 4px;
      border: 1px solid var(--border-color); }
      .account-joined .value-prop .key-features .key-feature h3 {
        font-size: 2rem;
        padding-bottom: 10px; }
      .account-joined .value-prop .key-features .key-feature:hover {
        box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1); }
      .account-joined .value-prop .key-features .key-feature .info {
        font-size: 0.9rem; }

.marketing {
  color: var(--font-color);
  background-color: white; }
  .marketing h2 {
    font-size: 3rem; }
  .marketing p {
    font-size: 1.2rem; }
  .marketing .row {
    padding-bottom: 100px; }
  .marketing.shade {
    background-color: var(--grey-background-color); }

@media screen and (max-height: 600px) {
  /* override calculated height to 100% to implement min height of 600px */
  .splash .carousel-inner, .carousel-item {
    height: 600px; } }

@media (max-width: 768px) {
  .splash .carousel-inner, .splash .carousel-item {
    height: auto; }
  .splash .carousel-caption {
    bottom: 50px; }
    .splash .carousel-caption p, .splash .carousel-caption a {
      display: none; }
  .splash nav {
    height: 100px; }
    .splash nav .logo {
      margin: 5px auto;
      display: flex;
      position: inherit;
      left: auto; }
    .splash nav .splash-menu {
      top: 40px;
      margin: 0 auto;
      width: 70%; }
  .splash h3 {
    font-size: 2rem; }
  .splash p {
    font-size: 1rem; }
  .splash .value-prop {
    margin: 0 auto;
    padding: 10px 10px; } }

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	J O I N
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.join, .splash {
  min-height: calc(100vh);
  background: url("../../../public/media/team-around-table.jpg") no-repeat top center;
  background-color: black;
  color: white;
  object-fit: cover;
  background-size: calc(100vw); }
  .join .logo, .splash .logo {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    background: url("../../../public/media/logo-transparentwhite.svg") no-repeat center center;
    background-size: 211px;
    width: 270px;
    height: 37px; }
  .join .container, .splash .container {
    padding-top: 100px;
    padding-bottom: 100px; }
  .join a, .join button, .splash a, .splash button {
    color: var(--font-color); }
  @media (max-width: 768px) {
    .join .container, .splash .container {
      padding: 100px 0; }
    .join .splash-panel, .splash .splash-panel {
      max-width: unset;
      flex: 1;
      padding: 40px 15px; } }
  @media (max-width: 414px) {
    .join, .splash {
      background-color: var(--feature-colour);
      background-image: unset; } }

.splash .container {
  display: flex; }

.join-panel {
  margin: 0 auto;
  background-color: white;
  padding: 40px 60px 60px 60px;
  text-align: left;
  min-height: 300px;
  color: var(--font-color); }

.splash-panel {
  width: 70%;
  margin: 0 auto;
  background-color: white;
  padding: 40px 60px 60px 60px;
  text-align: left;
  min-height: 300px;
  color: var(--font-color); }

.onboarding-panel {
  width: 70%;
  margin: 0 auto;
  background-color: white;
  padding: 0 60px 60px 0;
  text-align: left;
  min-height: 300px;
  color: var(--font-color); }

@media (max-width: 768px) {
  .onboarding-panel {
    max-width: unset;
    flex: 1;
    padding: 0px;
    width: unset; } }

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	P R O F I L E
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.profiles h1, .user-edit h1 {
  text-transform: uppercase;
  overflow-wrap: break-word; }

.profiles span.surname-title, .user-edit span.surname-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 210px;
  display: block; }

.profile-photo {
  border-radius: 100%;
  width: 200px;
  height: 200px;
  max-width: 200px;
  border: 3px solid var(--grey-background-color);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: inline-block; }

.profile-thumbnail {
  width: 100%;
  border-radius: 100%;
  border: 2px solid var(--grey-background-color);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: inline-block; }
  .profile-thumbnail.mini {
    height: 24px;
    width: 24px;
    margin: 4px;
    border-width: 1px; }

.profile-thumbnail-tile .col-4 {
  padding-right: 0; }

.profile-tile {
  background-color: white;
  background-image: none;
  position: relative; }
  .profile-tile h4 {
    margin: 0; }
  .profile-tile .controls {
    float: right;
    position: absolute;
    right: 5px;
    top: 5px; }
    .profile-tile .controls .glyphicons {
      line-height: 0.5; }

ul.user-tags {
  list-style: none;
  padding-left: 0; }

.user-tags {
  min-height: 40px; }
  .user-tags:hover .add-new {
    display: block; }

.user-tag {
  border-radius: 2px;
  background-color: var(--feature-colour);
  padding: 4px;
  font-size: 9px;
  color: white;
  margin-right: 8px;
  margin-bottom: 18px; }
  .user-tag.pending {
    background-color: var(--feature-colour-light-1); }
  .user-tag.big {
    font-size: 12px;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    background-color: white; }
  .user-tag.deleted {
    text-decoration: line-through;
    background-color: var(--feature-colour-dark-2); }
  .user-tagbutton {
    color: inherit !important; }
  .user-tag.colour-1 {
    color: #34C40A;
    border-color: #34C40A; }
    .user-tag.colour-1.selected {
      color: white;
      background-color: #34C40A; }
  .user-tag.colour-2 {
    color: #8C0DFF;
    border-color: #8C0DFF; }
    .user-tag.colour-2.selected {
      color: white;
      background-color: #8C0DFF; }
  .user-tag.colour-3 {
    color: #E82A0C;
    border-color: #E82A0C; }
    .user-tag.colour-3.selected {
      color: white;
      background-color: #E82A0C; }
  .user-tag.colour-4 {
    color: #FFBF00;
    border-color: #FFBF00; }
    .user-tag.colour-4.selected {
      color: white;
      background-color: #FFBF00; }
  .user-tag.colour-5 {
    color: #00DDFF;
    border-color: #00DDFF; }
    .user-tag.colour-5.selected {
      color: white;
      background-color: #00DDFF; }
  .user-tag.add-new {
    border: 2px dashed var(--border-color);
    background-color: transparent;
    color: var(--font-minor-color) !important;
    display: none; }
  .user-tag:hover {
    text-decoration: none; }

button.user-tag {
  color: white !important; }

.user-status {
  border-radius: 2px;
  padding: 4px;
  font-size: 9px; }
  .user-status.ACTIVE {
    display: none; }
  .user-status.INACTIVE {
    background-color: var(--error-color);
    color: white; }
  .user-status.CANDIDATE {
    background-color: var(--warning-color);
    color: var(--font-color); }
  .user-status.APPLICANT {
    background-color: var(--error-color);
    color: white; }
  .user-status.ALIMNI {
    background-color: var(--warning-color);
    color: var(--font-color); }

.status-bar {
  min-height: 38px; }

@media (min-width: 1200px) {
  .profiles span.surname-title, .user-edit span.surname-title {
    width: 255px; } }

@media (max-width: 991px) {
  .profiles span.surname-title, .user-edit span.surname-title {
    width: 180px; } }

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	T A L E N T
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.talents-table .thumbnail {
  vertical-align: middle;
  text-align: center; }

.talents-table .actions {
  text-align: center;
  vertical-align: middle; }

.talents-table .profile-thumbnail {
  width: 96px;
  height: 96px; }

.bar-chart .progress {
  margin-bottom: 5px;
  border-radius: 0; }
  .bar-chart .progress .progress-bar {
    height: 30px; }
  .bar-chart .progress .progress-bar-label {
    line-height: 30px;
    font-size: 0.75rem; }

.bar-chart a {
  color: white; }

.progress-bar-label {
  position: absolute;
  color: white;
  left: 5px; }

.code textarea {
  font-family: monospace;
  font-size: 12px; }

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	A D D    T A L E N T  /  J O B   A P L I C A T I O N
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.apply, .onboard {
  line-height: 1.5rem; }
  .apply .wizard-body, .onboard .wizard-body {
    min-height: 300px; }
    .apply .wizard-body div, .apply .wizard-body p, .onboard .wizard-body div, .onboard .wizard-body p {
      font-size: 1rem; }
    .apply .wizard-body .experience, .apply .wizard-body .education, .onboard .wizard-body .experience, .onboard .wizard-body .education {
      border-bottom: 1px dashed var(--border-color);
      padding: 5px 0; }
    .apply .wizard-body .availability, .onboard .wizard-body .availability {
      text-align: center;
      font-size: 3rem; }
  .apply .wizard-footer, .onboard .wizard-footer {
    text-align: right;
    border-top: 1px solid var(--border-color);
    padding-top: 10px; }
  .apply.finished, .onboard.finished {
    text-align: center; }
    .apply.finished .wizard-body, .onboard.finished .wizard-body {
      margin-top: 40px;
      min-height: unset; }

.wizard-outer {
  width: 70%;
  margin: 0 auto; }

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	S E A R C H
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.search-criteria-builder .search-criteria {
  overflow: hidden;
  padding: 8px;
  background-color: var(--grey-background-color);
  margin-bottom: 8px; }
  .search-criteria-builder .search-criteria .criteria > div {
    float: left;
    padding-right: 4px;
    height: 35px;
    line-height: 32px; }
  .search-criteria-builder .search-criteria .criteria .criteria-index {
    width: 20px; }
  .search-criteria-builder .search-criteria .criteria .criteria-field {
    width: 200px; }
  .search-criteria-builder .search-criteria .criteria .criteria-value {
    width: 300px; }
  .search-criteria-builder .search-criteria .criteria .criteria-actions {
    float: right; }
  .search-criteria-builder .search-criteria .criteria .form-group {
    margin-bottom: 0; }

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	S E T T I N G S
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.settings .settings-tile {
  border-radius: 4px;
  border: none;
  margin-bottom: 10px;
  position: relative;
  background-color: var(--grey-background-color);
  padding: 10px;
  min-height: 70px; }

.settings h4 {
  font-size: 1.2rem;
  margin-bottom: 0;
  line-height: 2rem; }

.settings .setting-value {
  font-weight: 300; }

.settings tr.settings-tile {
  background-color: inherit;
  height: 50px; }

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	G E N E R A L
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.icon {
  display: inline-flex;
  align-content: center;
  padding: 4px; }

.clickable {
  cursor: pointer; }
