.subscription-layout {
  min-height: 100vh;
  background-color: var(--grey-color); }
  .subscription-layout .container {
    max-width: 600px; }
    .subscription-layout .container header {
      text-align: center;
      padding: 24px 0; }
      .subscription-layout .container header .brand {
        width: 90px; }
    .subscription-layout .container.main {
      background-color: white;
      padding: 40px 60px;
      border-radius: 8px; }
  .subscription-layout .timesheet-header {
    text-align: center; }
    .subscription-layout .timesheet-header__details {
      background-color: var(--grey-color);
      padding: 16px;
      margin-top: 16px;
      border-radius: 8px 8px 0 0; }
  .subscription-layout .review-status {
    background-color: var(--grey-color);
    padding: 8px 0;
    text-transform: capitalize; }
    .subscription-layout .review-status.ACCEPTED {
      background-color: var(--ok-color);
      color: white; }
    .subscription-layout .review-status.REJECTED {
      background-color: var(--error-color);
      color: white; }
  .subscription-layout .timesheet-name {
    font-size: 1.5rem; }
  .subscription-layout .timesheet-date {
    font-size: 1.2rem;
    display: flex; }
    .subscription-layout .timesheet-date .date {
      flex: 1; }
    .subscription-layout .timesheet-date .skip {
      display: block;
      min-width: 60px; }
  .subscription-layout .timesheet-table {
    background-color: var(--background-colour-light1);
    padding: 16px;
    min-height: 340px;
    border-bottom: #fff 5px dotted; }
  .subscription-layout table {
    width: 100%;
    background-color: transparent; }
  .subscription-layout .timesheet-entry {
    border-bottom: 1px solid var(--border-color);
    background-color: transparent; }
    .subscription-layout .timesheet-entry > td {
      padding: 10px 0; }
    .subscription-layout .timesheet-entry__value {
      text-align: right; }
  .subscription-layout .timesheet-logs {
    border-top: 1px solid var(--border-color);
    padding-top: 16px;
    margin-top: 16px; }
  .subscription-layout .subscriber {
    background-color: var(--grey-color);
    padding: 16px;
    border-radius: 0 0 8px 8px; }
    .subscription-layout .subscriber label {
      font-weight: bold; }
  .subscription-layout .subscriber-reviews {
    border-top: 1px solid var(--border-color);
    padding: 12px 0; }
  .subscription-layout .subscriber-actions {
    padding: 12px 0;
    display: flex;
    justify-items: flex-end; }
    .subscription-layout .subscriber-actions .download {
      flex: 1;
      text-align: right; }
  .subscription-layout .badge.status-DRAFT {
    background-color: var(--warning-color);
    padding: 5px 10px; }
  .subscription-layout .badge.status-SUBMITTED {
    background-color: var(--feature-colour);
    color: white;
    padding: 5px 10px; }
  .subscription-layout .badge.status-APPROVED {
    background-color: var(--ok-color);
    color: white;
    padding: 5px 10px; }
  .subscription-layout .badge.status-REJECTED {
    background-color: var(--error-color);
    color: white;
    padding: 5px 10px; }
