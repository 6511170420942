/* Bootstrap Overrides */
.input-group .input-group-addon {
  padding: 2px; }

.navbar-inverse {
  background-color: var(--feature-colour);
  background-image: none; }

.navbar-inverse .navbar-brand {
  color: white; }

/**/
