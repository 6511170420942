.skill-header {
  padding-bottom: 50px; }

.recent-skill .category {
  height: 1.5rem; }

.recent-skill .description {
  height: 3rem;
  overflow: hidden; }

.bar-chart .progress {
  height: auto; }

.skill-description {
  border-top: 1px solid var(--border-color);
  padding-top: 20px;
  margin-top: 20px;
  min-height: 200px; }

.skills-related {
  padding-top: 20px;
  margin-top: 20px; }

.talent-by-skill, .skills, .talent, .data-items {
  position: relative; }
  .talent-by-skill .grid, .skills .grid, .talent .grid, .data-items .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    padding: 10px 0; }
  .talent-by-skill.top-experts .grid, .skills.top-experts .grid, .talent.top-experts .grid, .data-items.top-experts .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    padding: 0; }
  .talent-by-skill a:hover, .skills a:hover, .talent a:hover, .data-items a:hover {
    text-decoration: none; }
  .talent-by-skill .talent-skill, .talent-by-skill .skill-type, .talent-by-skill .skill-availability, .talent-by-skill .timesheet, .skills .talent-skill, .skills .skill-type, .skills .skill-availability, .skills .timesheet, .talent .talent-skill, .talent .skill-type, .talent .skill-availability, .talent .timesheet, .data-items .talent-skill, .data-items .skill-type, .data-items .skill-availability, .data-items .timesheet {
    display: grid;
    grid-template-columns: 100px auto;
    height: 170px;
    overflow: hidden;
    padding: 8px;
    background-color: var(--grey-background-color);
    position: relative;
    overflow: hidden; }
    .talent-by-skill .talent-skill .details, .talent-by-skill .skill-type .details, .talent-by-skill .skill-availability .details, .talent-by-skill .timesheet .details, .skills .talent-skill .details, .skills .skill-type .details, .skills .skill-availability .details, .skills .timesheet .details, .talent .talent-skill .details, .talent .skill-type .details, .talent .skill-availability .details, .talent .timesheet .details, .data-items .talent-skill .details, .data-items .skill-type .details, .data-items .skill-availability .details, .data-items .timesheet .details {
      padding: 4px;
      overflow: hidden; }
    .talent-by-skill .talent-skill .name, .talent-by-skill .skill-type .name, .talent-by-skill .skill-availability .name, .talent-by-skill .timesheet .name, .skills .talent-skill .name, .skills .skill-type .name, .skills .skill-availability .name, .skills .timesheet .name, .talent .talent-skill .name, .talent .skill-type .name, .talent .skill-availability .name, .talent .timesheet .name, .data-items .talent-skill .name, .data-items .skill-type .name, .data-items .skill-availability .name, .data-items .timesheet .name {
      text-transform: uppercase;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .talent-by-skill .talent-skill .split-line, .talent-by-skill .skill-type .split-line, .talent-by-skill .skill-availability .split-line, .talent-by-skill .timesheet .split-line, .skills .talent-skill .split-line, .skills .skill-type .split-line, .skills .skill-availability .split-line, .skills .timesheet .split-line, .talent .talent-skill .split-line, .talent .skill-type .split-line, .talent .skill-availability .split-line, .talent .timesheet .split-line, .data-items .talent-skill .split-line, .data-items .skill-type .split-line, .data-items .skill-availability .split-line, .data-items .timesheet .split-line {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .talent-by-skill .talent-skill .headline, .talent-by-skill .skill-type .headline, .talent-by-skill .skill-availability .headline, .talent-by-skill .timesheet .headline, .skills .talent-skill .headline, .skills .skill-type .headline, .skills .skill-availability .headline, .skills .timesheet .headline, .talent .talent-skill .headline, .talent .skill-type .headline, .talent .skill-availability .headline, .talent .timesheet .headline, .data-items .talent-skill .headline, .data-items .skill-type .headline, .data-items .skill-availability .headline, .data-items .timesheet .headline {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .talent-by-skill .talent-skill .thumbnail, .talent-by-skill .skill-type .thumbnail, .talent-by-skill .skill-availability .thumbnail, .talent-by-skill .timesheet .thumbnail, .skills .talent-skill .thumbnail, .skills .skill-type .thumbnail, .skills .skill-availability .thumbnail, .skills .timesheet .thumbnail, .talent .talent-skill .thumbnail, .talent .skill-type .thumbnail, .talent .skill-availability .thumbnail, .talent .timesheet .thumbnail, .data-items .talent-skill .thumbnail, .data-items .skill-type .thumbnail, .data-items .skill-availability .thumbnail, .data-items .timesheet .thumbnail {
      text-align: center;
      padding: 4px; }
      .talent-by-skill .talent-skill .thumbnail .profile-thumbnail, .talent-by-skill .skill-type .thumbnail .profile-thumbnail, .talent-by-skill .skill-availability .thumbnail .profile-thumbnail, .talent-by-skill .timesheet .thumbnail .profile-thumbnail, .skills .talent-skill .thumbnail .profile-thumbnail, .skills .skill-type .thumbnail .profile-thumbnail, .skills .skill-availability .thumbnail .profile-thumbnail, .skills .timesheet .thumbnail .profile-thumbnail, .talent .talent-skill .thumbnail .profile-thumbnail, .talent .skill-type .thumbnail .profile-thumbnail, .talent .skill-availability .thumbnail .profile-thumbnail, .talent .timesheet .thumbnail .profile-thumbnail, .data-items .talent-skill .thumbnail .profile-thumbnail, .data-items .skill-type .thumbnail .profile-thumbnail, .data-items .skill-availability .thumbnail .profile-thumbnail, .data-items .timesheet .thumbnail .profile-thumbnail {
        height: 80px;
        width: 80px; }
    .talent-by-skill .talent-skill .summary, .talent-by-skill .skill-type .summary, .talent-by-skill .skill-availability .summary, .talent-by-skill .timesheet .summary, .skills .talent-skill .summary, .skills .skill-type .summary, .skills .skill-availability .summary, .skills .timesheet .summary, .talent .talent-skill .summary, .talent .skill-type .summary, .talent .skill-availability .summary, .talent .timesheet .summary, .data-items .talent-skill .summary, .data-items .skill-type .summary, .data-items .skill-availability .summary, .data-items .timesheet .summary {
      height: 52px;
      overflow: hidden; }
    .talent-by-skill .talent-skill .strength, .talent-by-skill .skill-type .strength, .talent-by-skill .skill-availability .strength, .talent-by-skill .timesheet .strength, .skills .talent-skill .strength, .skills .skill-type .strength, .skills .skill-availability .strength, .skills .timesheet .strength, .talent .talent-skill .strength, .talent .skill-type .strength, .talent .skill-availability .strength, .talent .timesheet .strength, .data-items .talent-skill .strength, .data-items .skill-type .strength, .data-items .skill-availability .strength, .data-items .timesheet .strength {
      padding: 4px; }
    .talent-by-skill .talent-skill:hover, .talent-by-skill .skill-type:hover, .talent-by-skill .skill-availability:hover, .talent-by-skill .timesheet:hover, .skills .talent-skill:hover, .skills .skill-type:hover, .skills .skill-availability:hover, .skills .timesheet:hover, .talent .talent-skill:hover, .talent .skill-type:hover, .talent .skill-availability:hover, .talent .timesheet:hover, .data-items .talent-skill:hover, .data-items .skill-type:hover, .data-items .skill-availability:hover, .data-items .timesheet:hover {
      background-color: var(--background-colour-light1);
      box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1); }
    .talent-by-skill .talent-skill.add-new, .talent-by-skill .skill-type.add-new, .talent-by-skill .skill-availability.add-new, .talent-by-skill .timesheet.add-new, .skills .talent-skill.add-new, .skills .skill-type.add-new, .skills .skill-availability.add-new, .skills .timesheet.add-new, .talent .talent-skill.add-new, .talent .skill-type.add-new, .talent .skill-availability.add-new, .talent .timesheet.add-new, .data-items .talent-skill.add-new, .data-items .skill-type.add-new, .data-items .skill-availability.add-new, .data-items .timesheet.add-new {
      border: 2px dashed var(--border-color);
      grid-template-columns: 1fr;
      grid-template-rows: auto; }
    .talent-by-skill .talent-skill .add-label, .talent-by-skill .skill-type .add-label, .talent-by-skill .skill-availability .add-label, .talent-by-skill .timesheet .add-label, .skills .talent-skill .add-label, .skills .skill-type .add-label, .skills .skill-availability .add-label, .skills .timesheet .add-label, .talent .talent-skill .add-label, .talent .skill-type .add-label, .talent .skill-availability .add-label, .talent .timesheet .add-label, .data-items .talent-skill .add-label, .data-items .skill-type .add-label, .data-items .skill-availability .add-label, .data-items .timesheet .add-label {
      color: var(--font-minor-color);
      text-align: center;
      width: 100%;
      margin: auto auto; }
    .talent-by-skill .talent-skill.selected, .talent-by-skill .skill-type.selected, .talent-by-skill .skill-availability.selected, .talent-by-skill .timesheet.selected, .skills .talent-skill.selected, .skills .skill-type.selected, .skills .skill-availability.selected, .skills .timesheet.selected, .talent .talent-skill.selected, .talent .skill-type.selected, .talent .skill-availability.selected, .talent .timesheet.selected, .data-items .talent-skill.selected, .data-items .skill-type.selected, .data-items .skill-availability.selected, .data-items .timesheet.selected {
      border: 2px dashed var(--feature-colour-light-1); }
    .talent-by-skill .talent-skill .controls, .talent-by-skill .skill-type .controls, .talent-by-skill .skill-availability .controls, .talent-by-skill .timesheet .controls, .skills .talent-skill .controls, .skills .skill-type .controls, .skills .skill-availability .controls, .skills .timesheet .controls, .talent .talent-skill .controls, .talent .skill-type .controls, .talent .skill-availability .controls, .talent .timesheet .controls, .data-items .talent-skill .controls, .data-items .skill-type .controls, .data-items .skill-availability .controls, .data-items .timesheet .controls {
      position: absolute;
      top: 4px;
      right: 0;
      display: none; }
    .talent-by-skill .talent-skill .tools, .talent-by-skill .skill-type .tools, .talent-by-skill .skill-availability .tools, .talent-by-skill .timesheet .tools, .skills .talent-skill .tools, .skills .skill-type .tools, .skills .skill-availability .tools, .skills .timesheet .tools, .talent .talent-skill .tools, .talent .skill-type .tools, .talent .skill-availability .tools, .talent .timesheet .tools, .data-items .talent-skill .tools, .data-items .skill-type .tools, .data-items .skill-availability .tools, .data-items .timesheet .tools {
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0;
      display: none;
      background-color: var(--background-colour-light1);
      padding: 4px; }
    .talent-by-skill .talent-skill:hover .controls, .talent-by-skill .talent-skill:hover .tools, .talent-by-skill .skill-type:hover .controls, .talent-by-skill .skill-type:hover .tools, .talent-by-skill .skill-availability:hover .controls, .talent-by-skill .skill-availability:hover .tools, .talent-by-skill .timesheet:hover .controls, .talent-by-skill .timesheet:hover .tools, .skills .talent-skill:hover .controls, .skills .talent-skill:hover .tools, .skills .skill-type:hover .controls, .skills .skill-type:hover .tools, .skills .skill-availability:hover .controls, .skills .skill-availability:hover .tools, .skills .timesheet:hover .controls, .skills .timesheet:hover .tools, .talent .talent-skill:hover .controls, .talent .talent-skill:hover .tools, .talent .skill-type:hover .controls, .talent .skill-type:hover .tools, .talent .skill-availability:hover .controls, .talent .skill-availability:hover .tools, .talent .timesheet:hover .controls, .talent .timesheet:hover .tools, .data-items .talent-skill:hover .controls, .data-items .talent-skill:hover .tools, .data-items .skill-type:hover .controls, .data-items .skill-type:hover .tools, .data-items .skill-availability:hover .controls, .data-items .skill-availability:hover .tools, .data-items .timesheet:hover .controls, .data-items .timesheet:hover .tools {
      display: block; }
    .talent-by-skill .talent-skill:hover .tools, .talent-by-skill .skill-type:hover .tools, .talent-by-skill .skill-availability:hover .tools, .talent-by-skill .timesheet:hover .tools, .skills .talent-skill:hover .tools, .skills .skill-type:hover .tools, .skills .skill-availability:hover .tools, .skills .timesheet:hover .tools, .talent .talent-skill:hover .tools, .talent .skill-type:hover .tools, .talent .skill-availability:hover .tools, .talent .timesheet:hover .tools, .data-items .talent-skill:hover .tools, .data-items .skill-type:hover .tools, .data-items .skill-availability:hover .tools, .data-items .timesheet:hover .tools {
      box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1); }
    .talent-by-skill .talent-skill .user-tags, .talent-by-skill .skill-type .user-tags, .talent-by-skill .skill-availability .user-tags, .talent-by-skill .timesheet .user-tags, .skills .talent-skill .user-tags, .skills .skill-type .user-tags, .skills .skill-availability .user-tags, .skills .timesheet .user-tags, .talent .talent-skill .user-tags, .talent .skill-type .user-tags, .talent .skill-availability .user-tags, .talent .timesheet .user-tags, .data-items .talent-skill .user-tags, .data-items .skill-type .user-tags, .data-items .skill-availability .user-tags, .data-items .timesheet .user-tags {
      height: 40px;
      overflow: hidden; }
    .talent-by-skill .talent-skill .user-status, .talent-by-skill .skill-type .user-status, .talent-by-skill .skill-availability .user-status, .talent-by-skill .timesheet .user-status, .skills .talent-skill .user-status, .skills .skill-type .user-status, .skills .skill-availability .user-status, .skills .timesheet .user-status, .talent .talent-skill .user-status, .talent .skill-type .user-status, .talent .skill-availability .user-status, .talent .timesheet .user-status, .data-items .talent-skill .user-status, .data-items .skill-type .user-status, .data-items .skill-availability .user-status, .data-items .timesheet .user-status {
      text-align: center; }
  .talent-by-skill .talent-skill, .talent-by-skill .skill-availability, .skills .talent-skill, .skills .skill-availability, .talent .talent-skill, .talent .skill-availability, .data-items .talent-skill, .data-items .skill-availability {
    min-height: 165px;
    grid-template-rows: auto auto; }
    .talent-by-skill .talent-skill .availability, .talent-by-skill .talent-skill .extras, .talent-by-skill .skill-availability .availability, .talent-by-skill .skill-availability .extras, .skills .talent-skill .availability, .skills .talent-skill .extras, .skills .skill-availability .availability, .skills .skill-availability .extras, .talent .talent-skill .availability, .talent .talent-skill .extras, .talent .skill-availability .availability, .talent .skill-availability .extras, .data-items .talent-skill .availability, .data-items .talent-skill .extras, .data-items .skill-availability .availability, .data-items .skill-availability .extras {
      grid-column-start: span 2;
      padding: 5px 5px 0 5px;
      border-top: 1px solid var(--border-color); }
    .talent-by-skill .talent-skill .extras, .talent-by-skill .skill-availability .extras, .skills .talent-skill .extras, .skills .skill-availability .extras, .talent .talent-skill .extras, .talent .skill-availability .extras, .data-items .talent-skill .extras, .data-items .skill-availability .extras {
      display: grid;
      grid-template-columns: 1fr 1fr; }
      .talent-by-skill .talent-skill .extras .user-tags, .talent-by-skill .skill-availability .extras .user-tags, .skills .talent-skill .extras .user-tags, .skills .skill-availability .extras .user-tags, .talent .talent-skill .extras .user-tags, .talent .skill-availability .extras .user-tags, .data-items .talent-skill .extras .user-tags, .data-items .skill-availability .extras .user-tags {
        min-height: unset;
        height: auto;
        text-align: right; }
        .talent-by-skill .talent-skill .extras .user-tags .user-tag, .talent-by-skill .skill-availability .extras .user-tags .user-tag, .skills .talent-skill .extras .user-tags .user-tag, .skills .skill-availability .extras .user-tags .user-tag, .talent .talent-skill .extras .user-tags .user-tag, .talent .skill-availability .extras .user-tags .user-tag, .data-items .talent-skill .extras .user-tags .user-tag, .data-items .skill-availability .extras .user-tags .user-tag {
          margin-bottom: unset;
          margin-right: 0;
          margin-left: 8px; }
    .talent-by-skill .talent-skill .available-when, .talent-by-skill .talent-skill .available-when div, .talent-by-skill .talent-skill .available-when a, .talent-by-skill .skill-availability .available-when, .talent-by-skill .skill-availability .available-when div, .talent-by-skill .skill-availability .available-when a, .skills .talent-skill .available-when, .skills .talent-skill .available-when div, .skills .talent-skill .available-when a, .skills .skill-availability .available-when, .skills .skill-availability .available-when div, .skills .skill-availability .available-when a, .talent .talent-skill .available-when, .talent .talent-skill .available-when div, .talent .talent-skill .available-when a, .talent .skill-availability .available-when, .talent .skill-availability .available-when div, .talent .skill-availability .available-when a, .data-items .talent-skill .available-when, .data-items .talent-skill .available-when div, .data-items .talent-skill .available-when a, .data-items .skill-availability .available-when, .data-items .skill-availability .available-when div, .data-items .skill-availability .available-when a {
      font-size: 0.8rem;
      color: var(--font-minor-color); }
  .talent-by-skill .skill-availability .extras, .skills .skill-availability .extras, .talent .skill-availability .extras, .data-items .skill-availability .extras {
    grid-template-columns: 1fr; }
  .talent-by-skill .skill-type, .skills .skill-type, .talent .skill-type, .data-items .skill-type {
    display: block;
    /*grid-template-columns: 1fr;
		grid-template-rows: 4fr 1fr;*/ }
    .talent-by-skill .skill-type .name, .skills .skill-type .name, .talent .skill-type .name, .data-items .skill-type .name {
      text-transform: none; }
  .talent-by-skill .data-divider, .skills .data-divider, .talent .data-divider, .data-items .data-divider {
    grid-column-start: span 4;
    background-color: var(--background-colour-light1);
    padding: 20px;
    font-weight: bold; }
  @media (max-width: 1200px) {
    .talent-by-skill .grid, .skills .grid, .talent .grid, .data-items .grid {
      grid-template-columns: 1fr 1fr 1fr; }
      .talent-by-skill .grid .data-divider, .skills .grid .data-divider, .talent .grid .data-divider, .data-items .grid .data-divider {
        grid-column-start: span 3; } }
  @media (max-width: 991px) {
    .talent-by-skill .grid, .skills .grid, .talent .grid, .data-items .grid {
      grid-template-columns: 1fr 1fr; }
      .talent-by-skill .grid .data-divider, .skills .grid .data-divider, .talent .grid .data-divider, .data-items .grid .data-divider {
        grid-column-start: span 2; } }
  @media (max-width: 576px) {
    .talent-by-skill .grid, .skills .grid, .talent .grid, .data-items .grid {
      grid-template-columns: 1fr; }
      .talent-by-skill .grid .data-divider, .skills .grid .data-divider, .talent .grid .data-divider, .data-items .grid .data-divider {
        grid-column-start: span 1; } }

.skills .grid {
  grid-template-columns: 1fr 1fr 1fr; }

.skill-type.status-deleted, .skill-type.status-deleted a {
  text-decoration: line-through;
  color: var(--font-minor-color); }

.skill-type.status-merged, .skill-type.status-merged a {
  text-decoration: line-through;
  color: var(--font-minor-color); }

.skill-conversations {
  background-color: var(--grey-background-color);
  padding: 8px;
  position: relative; }
  .skill-conversations .skill-conversation-post {
    padding: 15px 0;
    border-top: 1px solid var(--border-color); }
    .skill-conversations .skill-conversation-post .header {
      display: grid;
      grid-template-columns: 60px auto; }
      .skill-conversations .skill-conversation-post .header .profile-thumbnail {
        height: 50px;
        width: 50px; }
      .skill-conversations .skill-conversation-post .header .name {
        text-transform: uppercase;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .skill-conversations .skill-conversation-post .header .timestamp {
        font-size: 0.8rem;
        color: var(--font-minor-color); }
    .skill-conversations .skill-conversation-post .subject {
      font-weight: bold;
      margin: 6px 0; }
    .skill-conversations .skill-conversation-post .body {
      font-size: 0.9rem; }
  .skill-conversations .skill-conversation-post:first-child {
    border-top: none; }
