.glyphicon-button {
  cursor: pointer; }
  .glyphicon-button label {
    display: block;
    line-height: 24px; }
  .glyphicon-button:hover .invert {
    filter: invert(0.3); }

.glyphicon, .icon {
  width: 24px;
  height: 24px;
  padding: 2px;
  margin: 0 auto; }
  .glyphicon svg, .icon svg {
    width: 16px;
    height: 16px; }
  .glyphicon.invert, .glyphicon.invert svg, .icon.invert, .icon.invert svg {
    filter: invert(1);
    color: white; }
  .glyphicon.shade, .icon.shade {
    filter: invert(0.7); }
  .glyphicon.small, .glyphicon.small svg, .icon.small, .icon.small svg {
    width: 24px;
    height: 24px;
    padding: 2px; }
  .glyphicon.big, .glyphicon.big svg, .icon.big, .icon.big svg {
    width: 32px;
    height: 32px;
    padding: 2px; }
  .glyphicon.huge, .glyphicon.huge svg, .icon.huge, .icon.huge svg {
    width: 64px;
    height: 64px;
    padding: 2px; }
  .glyphicon.grey, .icon.grey {
    filter: invert(0.7); }
  .glyphicon.blue, .icon.blue {
    filter: invert(58%) sepia(98%) saturate(1724%) hue-rotate(178deg) brightness(99%) contrast(95%); }

.glyphicon-labelled {
  cursor: pointer;
  vertical-align: middle;
  line-height: 24px;
  cursor: pointer; }
  .glyphicon-labelled .glyphicon {
    margin-bottom: 2px; }
