.skills, .features {
  list-style-type: none;
  padding: 0; }
  .skills li, .features li {
    padding-bottom: 5px; }
  .skills .progress, .features .progress {
    height: 10px; }
  .skills h3, .features h3 {
    font-size: 1.2rem; }

.feature .value {
  font-size: 0.9rem; }

.educations {
  list-style-type: none;
  padding: 0;
  position: relative;
  overflow: hidden; }
  .educations h3 {
    font-size: 18px; }
  .educations li {
    position: relative;
    overflow: hidden;
    padding-bottom: 15px; }

.experience {
  position: relative;
  overflow: hidden;
  padding: 10px 4px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--border-color); }
  .experience .description {
    overflow: hidden; }
    .experience .description.collapsed {
      max-height: 200px; }
  .experience.experience-hidden {
    background-color: var(--grey-background-color); }
  .experience img {
    max-width: 100%;
    display: block;
    max-height: 200px; }
  .experience img.feature-image {
    max-width: 50%;
    float: right; }

.experience .attachments.tiles, .experience-attachments .attachments.tiles {
  display: flex;
  flex-wrap: wrap; }
  .experience .attachments.tiles .tile, .experience-attachments .attachments.tiles .tile {
    max-height: 100px;
    max-width: 100px; }
    .experience .attachments.tiles .tile img, .experience-attachments .attachments.tiles .tile img {
      object-fit: contain;
      width: 100px;
      height: 100px; }
  .experience .attachments.tiles .attachment, .experience-attachments .attachments.tiles .attachment {
    cursor: pointer;
    display: flex;
    flex-direction: column; }
    .experience .attachments.tiles .attachment:hover .attachment-label, .experience-attachments .attachments.tiles .attachment:hover .attachment-label {
      display: block; }
  .experience .attachments.tiles .add-label, .experience-attachments .attachments.tiles .add-label {
    width: 100px; }
  .experience .attachments.tiles .attachment-label, .experience-attachments .attachments.tiles .attachment-label {
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 0.8rem;
    background-color: var(--grey-background-color); }

.attachment-preview {
  overflow: hidden; }
  .attachment-preview img {
    object-fit: cover;
    width: 100%;
    max-height: 300px; }

.experience .controls, .education .controls, .skill .controls, .feature .controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none; }

.experience:hover .controls, .education:hover .controls, .skill:hover .controls, .feature:hover .controls {
  display: block; }

.skill, .feature {
  position: relative; }
  .skill .controls, .feature .controls {
    top: -2px;
    right: -5px; }
  .skill.disabled, .skill.disabled a, .feature.disabled, .feature.disabled a {
    color: var(--font-minor-color); }

.education {
  position: relative; }
  .education .controls {
    right: -5px; }

h3 {
  margin-bottom: 0; }

a {
  cursor: pointer; }

.profile-state-tile {
  border-radius: 4px;
  border: none;
  margin-bottom: 10px;
  position: relative;
  background-color: var(--grey-background-color);
  padding: 10px;
  min-height: 30px; }
  .profile-state-tile.draft {
    background-color: var(--error-background-color);
    color: var(--error-color); }
  .profile-state-tile .controls {
    display: none;
    position: relative;
    top: auto;
    right: 0;
    padding-right: 0; }
    .profile-state-tile .controls .glyphicons:before {
      padding-right: 0; }
  .profile-state-tile:hover .controls {
    display: block; }

.share-link {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px;
  width: 100%;
  overflow: hidden; }
  .share-link label, .share-link a {
    display: inline-block; }
  .share-link a {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.profiles .info-box {
  margin: 10px 0;
  padding: 10px;
  border-radius: 0; }
